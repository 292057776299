.rdtPicker {
    width: initial;
    min-width: 250px;
}

.rdtNext > span {
    font-size: 21px !important;
}

.rdtPrev > span {
    font-size: 21px !important;
}