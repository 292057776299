.exporter-container .DayPicker_transitionContainer {
    width: 700px !important;
    height: 370px !important;
}

.exporter-container .DayPicker {
    width: 700px !important;
}

.exporter-container .DayPicker > div > div {
    width: 700px !important;
}

.exporter-container .DateInput input {
    margin: 0px !important;
}

.exporter-container .DateRangePickerInput_clearDates {
    right: 20px;
}

.exporter-container .DayPicker_weekHeader_li {
    width: 45px !important;
}

.exporter-container .DayPicker_weekHeader:nth-child(2) {
    left: 340px !important;
}