#applicant-tabs a {
    padding: 10px 20px !important;
    font-size: 16px !important;
    color: #0079c1;
}

#applicant-tabs .active a {
    color: #333;
}

#applicant-tabs li:first-of-type a {
    border-left-color: transparent !important;
    border-top-left-radius: 0;
}